import React from 'react';
import ReactDOM from 'react-dom';
// import "./index.css";
import App from './app';
import { Auth0Provider } from '@auth0/auth0-react';
import { BrowserRouter as Router } from 'react-router-dom';
import { getConfig } from './config';
import history from './utils/history';
import { ThemeProvider } from './theme/ThemeContext';
import Auth0ProviderWithHistory from './auth/auth0-provider-with-history';

ReactDOM.render(
  <Router>
    <Auth0ProviderWithHistory>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Auth0ProviderWithHistory>
  </Router>,
  document.getElementById('root')
);
