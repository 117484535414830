import React from "react";

import MainNav from "./main-nav";

import { ThemeSwitch } from "../components";

import { NavBarWrapper } from "../elements";

const NavBar = () => {
  return (
    <NavBarWrapper>
      <nav>
        <div>
          <MainNav />
        </div>
      </nav>
      <div className={"logo"}>
        <ThemeSwitch />
      </div>
    </NavBarWrapper>
  );
};

export default NavBar;
