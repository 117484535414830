import React, { useContext } from 'react';
import { Route, Switch } from 'react-router-dom';

import { NavBar, Footer } from './components';
import { Home, Profile, ExternalApi } from './views';
import { GlobalStyles, lightTheme, darkTheme } from './theme';
import { ThemeContext } from './theme/ThemeContext';

const App = () => {
  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;
  let themeChoice = darkMode ? lightTheme : darkTheme;

  return (
    <div id="app">
      <GlobalStyles theme={themeChoice} />
      <NavBar />
      <div>
        <div>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/profile" component={Profile} />
            <Route path="/external-api" component={ExternalApi} />
          </Switch>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default App;
