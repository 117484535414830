import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import {LoginButtonWrapper} from "../elements"

const LoginButton = () => {
  const { loginWithRedirect } = useAuth0();
  return (
    <LoginButtonWrapper onClick={() => loginWithRedirect()}>Log In</LoginButtonWrapper>
  );
};

export default LoginButton;