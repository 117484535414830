import React, { useContext, useEffect, useState } from 'react';
import { HomeContentWrapper } from '../elements';
import { ThemeContext } from '../theme/ThemeContext';
import { useAuth0 } from '@auth0/auth0-react';

const HomeContent = () => {
  const { user, isAuthenticated, getAccessTokenSilently } = useAuth0();
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;
  const api_endpoint = process.env.REACT_APP_API_ENDPOINT;
  const [userMetadata, setUserMetadata] = useState(null);
  useEffect(() => {
    if (isAuthenticated) {
      const getUserMetadata = async () => {
        const domain = 'psdmedia.eu.auth0.com';
        try {
          const accessToken = await getAccessTokenSilently({
            audience: audience,
            scope: 'read:current_user',
          });
          const metadataResponse = await fetch(api_endpoint, {
            headers: {
              Authorization: `Bearer ${accessToken}`,
            },
          });

          const u_m = await metadataResponse.json();
          // setUserMetadata(user_metadata);
          console.log('user metadata: ', u_m);

          return u_m;
        } catch (e) {
          return e.message;
        }
      };
      const token = getUserMetadata();
      console.log('token: ', token);
    }
  }, [getAccessTokenSilently, user?.sub]);

  const theme = useContext(ThemeContext);
  const darkMode = theme.state.darkMode;

  const dark_url =
    'https://s3.amazonaws.com/logo.psdmedia/psdMedia/psdMedia_dark.svg';
  const light_url =
    'https://s3.amazonaws.com/logo.psdmedia/psdMedia/psdMedia_light.svg';
  const url = darkMode ? dark_url : light_url;
  return (
    <HomeContentWrapper>
      <div className={'tampa'}>
        <div className={'tempa'}>
          <img src={url} />
          <h1>PSDMedia</h1>
        </div>
      </div>
      {/* {isAuthenticated && (
        <div>
          <img src={user.picture} alt={user.name} />
          <h2>{user.name}</h2>
          <p>{user.email}</p>
          <h3>User Metadata</h3>
          {userMetadata ? (
            <pre>{JSON.stringify(userMetadata, null, 2)}</pre>
          ) : (
            "No user metadata defined"
          )}
        </div>
      )} */}
    </HomeContentWrapper>
  );
};

export default HomeContent;
