import React from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { LoginButtonWrapper } from '../elements';

const LogoutButton = () => {
  const { logout } = useAuth0();
  return (
    <LoginButtonWrapper
      onClick={() =>
        logout({
          returnTo: window.location.origin,
        })
      }
    >
      Log Out
    </LoginButtonWrapper>
  );
};

export default LogoutButton;
