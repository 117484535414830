import React from "react";
// import { Container, Row, Col } from "reactstrap";

import { useAuth0} from "@auth0/auth0-react";

export const Profile = () => {
  const { user } = useAuth0();

  return (
    <div>
      <h1>PROFILE</h1>
    </div>
  );
};

export default Profile;
