import styled from 'styled-components'
export const HomeContentWrapper = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  /* color: black; */
  /* background-color: honeydew; */
  font-family: "Andale Mono", Helvetica, sans-serif;
.tampa{
  display: grid;
  grid-template-columns: 1fr auto 1fr;
  grid-row-gap: 30px;
  grid-template-rows: 1fr auto 1fr auto;
}
.tempa{
  grid-column: 2;
  grid-row: 2;
  display: flex;
  justify-content: center;
}
.train{
  grid-row: 4;
  grid-column: 2;
  display: flex;
  justify-content: center;
  align-items: center;
}
h1{
  writing-mode: vertical-rl;
  font-size: 0.97rem;
  font-weight: 100;
  margin: 0;
  padding: 0;
  padding-left: 0px;
  margin-top: -0.1rem;
  line-height: 1rem;
}
`