import { NavLink } from "react-router-dom";
import React from "react";
import LoginButton from "./loginButton";
import LogoutButton from "./logoutButton";
import { MainNavWrapper } from "../elements";

import { useAuth0 } from "@auth0/auth0-react";

const MainNav = () => {
  const { isAuthenticated } = useAuth0();

  return (
    <MainNavWrapper>

      {/* {isAuthenticated && (
        <NavLink to="/profile" exact>
          Profile
        </NavLink>
      )} */}

      {!isAuthenticated && (
        <div className={"login"}>
          <LoginButton />
        </div>
      )}

      {isAuthenticated && (
        <div>
          <LogoutButton />
        </div>
      )}
    </MainNavWrapper>
  );
};
export default MainNav;
