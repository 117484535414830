import styled from 'styled-components'
export const  NavBarWrapper = styled.div`
display: grid;
grid-template-columns: 1fr 1fr;
padding:  1rem 0.5rem;
.logo{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    align-content: center;
    grid-column: 2;
}
.login{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    align-content: center;
}
`