import styled from 'styled-components'
export const MainNavWrapper = styled.div`
display: grid;
grid-template-columns: 1fr;
.home{
    /* display: flex; */
    /* align-items: center; */
    grid-column: 1;
    a{
        color: black;
        text-decoration: none;
    }
}
.homeOn{
    color: blue;
}
`